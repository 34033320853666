import * as React from "react";
import Container from "@mui/material/Container";
import styles from "./footer.module.css";
import Grid from "@mui/material/Grid";
import logo from "../assets/images/yesweigh-logo.svg";
import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { ROUTES } from "../const";

export default function FooterComponent() {
  return (
    <>
      <Grid className={styles.footer}>
        <Container>
          <Grid container className={styles.footerTop} spacing={2}>
            <Grid item lg={4} md={6} sm={12}>
              <div className={styles.footerInfo}>
                <img
                  alt="Interweighing Pvt LTD is the market leader specialized in electronic weighing and billing equipment like Cloud based POS scales"
                  width={180}
                  src={logo}
                />
                <p>
                  Interweighing Pvt Ltd. is the market leader specialized in
                  electronic weighing and billing equipment like Cloud based POS
                  scales, Barcode scales, Industrial scales and Analytical
                  scales.
                  <br />
                </p>
                <br />
                <span>Working Hours</span>
                <br />
                <br />
                <span>09:00AM - 05:30PM ( Monday - Saturday )</span>
              </div>
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <Grid>
                <Grid container>
                  <Grid item xs={6} lg={3}>
                    <h4>Useful Links</h4>
                    <ul>
                      <li>
                        <Link className={styles.link} to={ROUTES.HOME}>
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link className={styles.link} to={ROUTES.ABOUT_US}>
                          About us
                        </Link>
                      </li>
                      <li>
                        <Link className={styles.link} to={ROUTES.PRODUCTS}>
                          Products
                        </Link>
                      </li>
                      {/* <li>
                        <Link className={styles.link} to={ROUTES.DOWNLOADS}>
                          Downloads
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link className={styles.link} to={ROUTES.CONTACT_US}>
                          Contact Us
                        </Link>
                      </li> */}
                    </ul>
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <h4>About Us</h4>
                    <ul>
                      <li>
                        <Link
                          className={styles.link}
                          to={ROUTES.PRIVACY_POLICY}
                        >
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link className={styles.link} to={ROUTES.RETURN_POLICY}>
                          Return Policy
                        </Link>
                      </li>
                      <li>
                        <Link className={styles.link} to={ROUTES.COOKIE_POLICY}>
                          Cookie Policy
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={styles.link}
                          to={ROUTES.E_WASTE_MANAGEMENT}
                        >
                          E-waste Management
                        </Link>
                      </li>
                    </ul>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid container>
                      <Grid item xs={12} className={styles.footerLinks}>
                        <h4>Get in touch</h4>
                        <p>
                          Interweighing Pvt Ltd,
                          <br />
                          49/468-A, 2nd Floor City Center,
                          <br />
                          Vyttila, Ernakulam, Kerala-682019 India
                        </p>
                      </Grid>
                    </Grid>
                    <Grid container direction="row">
                      <Grid item xs={12} className={styles.contactNumbers}>
                        <h3>
                          <a href="tel:918803333444">+91 88033 33444</a>
                        </h3>
                      </Grid>
                      {/* <Grid item xs={6} className={styles.contactNumbers}>
                        <h3>
                          <a href="tel:917736226288">+91 77362 26288</a>
                        </h3>
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            className={styles.copyright}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <p>
                &copy; Copyright
                <strong>
                  <span> Yes Weigh</span>
                </strong>
                . All Rights Reserved.
              </p>
            </Grid>
            <Grid item className={styles.connectUs}>
              <div className={styles.socialLinks}>
                <a href="https://www.facebook.com/yesweigh.in">
                  <FaFacebookF />
                </a>
                <a href="https://instagram.com/yesweigh.in?igshid=ZGUzMzM3NWJiOQ==">
                  <FaInstagram />
                </a>
                <a href="https://www.youtube.com/@yesweighpvtltd5705/videos">
                  <FaYoutube />
                </a>
                <a href="https://www.linkedin.com/company/yesweigh">
                  <FaLinkedin />
                </a>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  );
}

import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
export default function PageNotFound() {
  return (
    <>
      <Header />
      <Container>
        <Grid>
          <h2>Page Not Found</h2>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

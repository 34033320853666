import * as React from "react";
import Container from "@mui/material/Container";
import styles from "./header.css";
import logo from "../assets/images/yesweigh-logo.svg";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../const";

import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import { WhatsApp } from "@material-ui/icons";

const drawerWidth = 240;

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 1 }}>
        <img
          alt="Interweighing Pvt LTD is the market leader specialized in electronic weighing and billing equipment like Cloud based POS scales"
          src={logo}
          width="60%"
        />
      </Typography>
      <Divider />
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ListItem sx={{ textAlign: "center" }}>
          <NavLink to={ROUTES.HOME}>HOME</NavLink>
        </ListItem>
        <ListItem sx={{ textAlign: "center" }}>
          <NavLink to={ROUTES.ABOUT_US}>ABOUT</NavLink>
        </ListItem>
        <ListItem>
          <NavLink to={ROUTES.PRODUCTS}>PRODUCTS</NavLink>
        </ListItem>
        {/* <ListItem>
          <NavLink to={ROUTES.NEWS}>
            NEWS
          </NavLink>
        </ListItem> */}
        {/* <ListItem>
          <NavLink to={ROUTES.CONTACT_US}>CONTACT</NavLink>
        </ListItem> */}
        {/* <ListItem>
          <a
            href="https://api.whatsapp.com/send?phone=+918803333444&text=We have visited your site.Interested in your products"
            className="WhatsappButton"
          >
            <WhatsApp /> Whatsapp
          </a>
        </ListItem> */}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Container sx={{ display: "flex" }} className={styles.header}>
      <AppBar
        component="nav"
        color="inherit"
        className={styles.appBar}
        sx={{
          boxShadow: "4px 4px 25px 1px rgba(0, 0, 0, 0.15);",
        }}
      >
        <Container disableGutters>
          <Toolbar>
            <Typography component="div" sx={{ flexGrow: 1 }}>
              <img
                alt="Interweighing Pvt LTD is the market leader specialized in electronic weighing and billing equipment like Cloud based POS scales"
                src={logo}
                width="35%"
              />
            </Typography>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <List
                sx={{
                  display: "inline-flex",
                  flexDirection: "row",
                }}
              >
                <ListItem>
                  <NavLink to={ROUTES.HOME}>HOME</NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to={ROUTES.ABOUT_US}>ABOUT</NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to={ROUTES.PRODUCTS}>PRODUCTS</NavLink>
                </ListItem>
                {/* <ListItem>
                  <NavLink to={ROUTES.NEWS}>
                    NEWS
                  </NavLink>
                </ListItem> */}
                {/* <ListItem>
                  <NavLink to={ROUTES.CONTACT_US}>CONTACT</NavLink>
                </ListItem> */}
                {/* <ListItem>
                  <a
                    href="https://api.whatsapp.com/send?phone=+918803333444&text=We have visited your site.Interested in your products"
                    className="WhatsappButton"
                  >
                    <WhatsApp /> Whatsapp
                  </a>
                </ListItem> */}
              </List>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Container>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;

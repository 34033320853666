import React, { Component } from "react";
import Slider from "react-slick";
import yesweighBannerOne from "../assets/images/banner-1.jpg";
import yesweighBannerTwo from "../assets/images/banner-2.jpg";
import yesweighBannerThree from "../assets/images/banner-3.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./banner.module.css";
// import LabelPrinter from "../assets/images/label.png";
// import RetailOne from "../assets/images/retail-1.png";
// import RetailTwo from "../assets/images/retail-2.png";
// import Precision from "../assets/images/precision.png";
// import { Container, Grid } from "@material-ui/core";
// import { ROUTES } from "../const";
// import { NavLink } from "react-router-dom";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      infinite: true,
      dots: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      swipeToSlide: true,
      arrows: false,
      lazyLoad: true,
    };
    return (
      <div className={styles.sliderWrappper}>
        <Slider {...settings} className={styles.slider}>
          <div className={styles.sliderItem}>
            <img
              alt="Interweighing Pvt LTD is the market leader specialized in electronic weighing and billing equipment like Cloud based POS scales"
              src={yesweighBannerOne}
              width="100%"
            />
          </div>
          <div className={styles.sliderItem}>
            <img
              alt="Interweighing Pvt LTD is the market leader specialized in electronic weighing and billing equipment like Cloud based POS scales"
              src={yesweighBannerTwo}
              width="100%"
            />
          </div>
          <div className={styles.sliderItem}>
            <img
              alt="Interweighing Pvt LTD is the market leader specialized in electronic weighing and billing equipment like Cloud based POS scales"
              src={yesweighBannerThree}
              width="100%"
            />
          </div>
          {/* <div className={styles.sliderItem}>
            <Container>
              <Grid container className={styles.bannerWrapper}>
                <Grid item xs={12} lg={6}>
                  <h1 className={styles.bannerTitle}>Label Printing Scale</h1>
                  <h4>SUPPORT ALL TYPE OF BARCODES AND QR CODES</h4>
                  <NavLink to={ROUTES.PRODUCTS}>
                    <div className={styles.viewButton}>View Products</div>
                  </NavLink>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <img
                    alt="Label Printing Scale"
                    src={LabelPrinter}
                    width="100%"
                  />
                </Grid>
              </Grid>
            </Container>
          </div>
          <div className={styles.sliderItem}>
            <Container>
              <Grid container className={styles.bannerWrapper}>
                <Grid item xs={12} lg={6}>
                  <h1 className={styles.bannerTitle}>Precision Balance!</h1>
                  <h4>PRINTS WIDE VERITY OF BARCODE AND LABELS INCLUDING QR</h4>
                  <NavLink to={ROUTES.PRODUCTS}>
                    <div className={styles.viewButton}>View Products</div>
                  </NavLink>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <img alt="Interweighing Pvt LTD is the market leader specialized in electronic weighing and billing equipment like Cloud based POS scales" src={Precision} width="100%" />
                </Grid>
              </Grid>
            </Container>
          </div> */}
          {/*<div className={styles.sliderItem}>
            <Container>
              <Grid container className={styles.bannerWrapper}>
                <Grid item xs={12} lg={6}>
                  <h1 className={styles.bannerTitle}>Retail Scales!</h1>
                  <h4>PRINTS WIDE VERITY OF BARCODE AND LABELS INCLUDING QR</h4>
                  <Button className={styles.viewButton}>View Products</Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <img alt="Interweighing Pvt LTD is the market leader specialized in electronic weighing and billing equipment like Cloud based POS scales" src={RetailTwo} width="100%" />
                </Grid>
              </Grid>
            </Container>
          </div> */}
        </Slider>
      </div>
    );
  }
}

import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Deeler from "../assets/images/deeler.jpg";
import styles from "./gallery.module.css";
import SliderBanner from "../components/BannerComponent";
export default function Gallery() {
  return (
    <>
      <Header />
      <SliderBanner />
      <Container>
        <Grid className={styles.gallerySection}>
          <Grid sx={{ textAlign: "center" }}>
            <h2>Our Gallery</h2>
            <h1>Latest Photos</h1>
          </Grid>
          <Grid container className={styles.shopImgGrid} spacing={2}>
            <Grid item sx={12} md={6} lg={4}>
              <img src={Deeler} width="100%" alt="Deeler" />
            </Grid>
            <Grid item sx={12} md={6} lg={4}>
              <img src={Deeler} width="100%" alt="Deeler" />
            </Grid>
            <Grid item sx={12} md={6} lg={4}>
              <img src={Deeler} width="100%" alt="Deeler" />
            </Grid>
            <Grid item sx={12} md={6} lg={4}>
              <img src={Deeler} width="100%" alt="Deeler" />
            </Grid>
            <Grid item sx={12} md={6} lg={4}>
              <img src={Deeler} width="100%" alt="Deeler" />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

import React from "react";
import Product from "../../assets/images/product.png";
import Slider from "react-slick";
import styles from "./slider.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Paper } from "@mui/material";
import { Link } from "react-router-dom";

const settings = {
  dots: true,
  infinite: true,
  speed: 800,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function ResponsiveSlider(props) {
  const { relatedProducts } = props;

  return (
    <Slider {...settings} className={styles.productSlider}>
      {relatedProducts &&
        relatedProducts.map((product) => {
          const productImage =
            product.images && product.images[0]
              ? product.images[0].url
              : Product;
          return (
            <Link to={`/products/${product.id}`} key={product.id}>
              <Grid item className={styles.itemCardWrapper}>
                <Paper className={styles.productImg}>
                  <img alt={product.name} src={productImage} width="100%" />
                  <h4>{product.name}</h4>
                </Paper>
              </Grid>
            </Link>
          );
        })}
    </Slider>
  );
}

export default ResponsiveSlider;

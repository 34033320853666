import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Grid, Paper, Link, IconButton } from "@mui/material";
import Container from "@mui/material/Container";
import Header from "../components/Header";
import styles from "./home.module.css";
import DefaultCategoryImage from "../assets/images/default-category.png";
import Footer from "../components/Footer";
import SliderBanner from "../components/BannerComponent";
import ProductDefaultImage from "../assets/images/label.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getCategories, getItems } from "../services/BackendServices";
import Loader from "../components/Loading/index";
import { useNavigate } from "react-router-dom";
import delay from "../components/Loading/LoadingDelay";
import { ROUTES } from "../const";
import { FaWhatsappSquare } from "react-icons/fa";

const settings = {
  dots: true,
  infinite: true,
  speed: 800,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default function PublicHome() {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const _getCategories = async () => {
      setIsLoading(true);
      const _categories = await getCategories();
      setCategories(_categories);
      setSelectedCategory(_categories[0]);
      await delay();
      setIsLoading(false);
    };
    _getCategories().then();
  }, []);

  useEffect(() => {
    if (!selectedCategory) {
      return;
    }
    const _getItems = async (selectedCategory) => {
      setIsLoading(true);
      const _items = await getItems(selectedCategory.id);
      setItems(_items);
      await delay();
      setIsLoading(false);
    };
    _getItems(selectedCategory).then();
  }, [selectedCategory]);

  const onProductClickHandler = (product) => {
    navigate(`/products/${product.id}`);
  };

  const handleCategorySelect = async (category) => {
    navigate(`/products?categoryId=${category.id}`);
  };
  return (
    <>
      <Header />
      <SliderBanner />
      <Loader isOpen={isLoading} />

      <Container>
        <Grid className={styles.category}>
          <Grid className={styles.sectionTitle}>
            <h4>Our Products</h4>
            <h2>Total Weighing Solutions</h2>
          </Grid>
          <Grid container className={styles.categoryWrapper}>
            {categories.map((category) => {
              return (
                <Grid item xs={4} lg={2} key={category.id}>
                  <div
                    onClick={() => {
                      handleCategorySelect(category);
                    }}
                    className={`${styles.categoryItemActive} ${styles.categoryItem}`}
                  >
                    <img
                      alt={category.description}
                      className={styles.categoryImg}
                      src={category.image || DefaultCategoryImage}
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <h4>{category.name}</h4>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Slider {...settings} className={styles.productSlider}>
          {items.map((item) => {
            const productImage =
              item.images && item.images[0]
                ? item.images[0].url
                : ProductDefaultImage;
            return (
              <Link to={`/products/${item.id}`} key={item.id}>
                <Grid
                  item
                  className={styles.itemCardWrapper}
                  onClick={() => {
                    onProductClickHandler(item);
                  }}
                >
                  <Paper className={styles.productImg}>
                    <img alt={item.description} src={productImage} width="100%" />
                    <h4>{item.name}</h4>
                  </Paper>
                </Grid>
              </Link>
            );
          })}
        </Slider>
        <Grid>
          <NavLink to={ROUTES.PRODUCTS}>
            <div className={styles.viewAll}>View All Products</div>
          </NavLink>
        </Grid>
      </Container>
      <Footer />

      <div className={styles.whatsAppIconLayout}>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://api.whatsapp.com/send?phone=918803333444&text=Hey%20YesWeigh,%20I%20have%20a%20question"
        >
          <IconButton aria-label="delete" size="large" color="success">
            <FaWhatsappSquare
              fontSize="inherit"
              className={styles.whatsAppIcon}
            />
          </IconButton>
        </a>
      </div>
    </>
  );
}

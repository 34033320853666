import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import styles from "./policy.module.css";
import SliderBanner from "../components/BannerComponent";
export default function ReturnPolicy() {
  return (
    <>
      <Header />
      <SliderBanner />
      <Container>
        <Grid className={styles.privacySection}>
          <Grid sx={{ textAlign: "center" }}>
            <h1>YES WEIGH : RETURN POLICY</h1>
          </Grid>
          <Grid className={styles.introduction}>
            <p>
              Electronic waste, or e-waste, is a term for electronic products
              that have become unwanted, non-working or obsolete, and have
              essentially reached the end of their useful life. As per E-waste
              Rule 2016, the E waste defined as ‘electrical and electronic
              equipment, whole or in part discarded as waste by the consumer or
              bulk consumer as well as rejects from manufacturing, refurbishment
              and repair processes. E-waste contains many valuable, recoverable
              materials such as aluminum, copper, gold, silver, plastics, and
              ferrous metals. In order to conserve natural resources and the
              energy needed to produce new electronic equipment from virgin
              resources, electronic equipment can be refurbished, reused, and
              recycled instead of being land filled. E-waste also contains toxic
              and hazardous materials including mercury, lead, cadmium,
              beryllium, chromium, and chemical flame retardants, which have the
              potential to leach into our soil and water.
            </p>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import styles from "./product.module.css";
// import SliderBanner from "../components/BannerComponent";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ResponsiveSlider from "../components/ResponsiveSlider/ResponsiveSlider";
import { getItems, getProductDetails } from "../services/BackendServices";
import Loader from "../components/Loading/index";
import delay from "../components/Loading/LoadingDelay";

export default function ProductDetails(props) {
  const { productId } = useParams();
  const { pathname } = useLocation();
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!productId) {
      return;
    }
    const _getProductDetails = async () => {
      setIsLoading(true);
      const _product = await getProductDetails(productId);
      setProduct(_product);
      await delay();
      setIsLoading(false);
    };
    _getProductDetails().then();
  }, [productId]);

  useEffect(() => {
    if (!product || !product.images || !product.images.length) {
      setSelectedImage(null);
      return;
    }

    if (
      selectedImage &&
      product.images.filter((image) => image.id === selectedImage.id).length
    ) {
      return;
    }

    setSelectedImage(product.images[0]);
  }, [productId, product, selectedImage, setSelectedImage]);

  useEffect(() => {
    if (!product || !product.categoryId) {
      return;
    }
    const _getItems = async () => {
      setIsLoading(true);
      const _products = await getItems(product.categoryId);
      setRelatedProducts(_products);
      await delay();
      setIsLoading(false);
    };
    _getItems().then();
  }, [product, setRelatedProducts]);

  return (
    <>
      <Header />
      {/* <SliderBanner /> */}
      <Loader isOpen={isLoading} />
      <Container className={styles.productDetailSection}>
        <Grid container spacing={2}>
          {product?.images && (
            <Grid item xs={12} lg={6}>
              {selectedImage && (
                <Grid>
                  <img
                    alt={product?.description || ""}
                    className={styles.categoryImg}
                    src={selectedImage.url}
                    width="100%"
                  />
                </Grid>
              )}
              <Grid container>
                {product?.images.map((image) => {
                  return (
                    <Grid
                      item
                      xs={3}
                      lg={3}
                      key={image.id}
                      onClick={() => {
                        setSelectedImage(image);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        alt={product?.description || ""}
                        className={styles.categoryImg}
                        src={image.url}
                        width="100%"
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} lg={6}>
            <h2 className={styles.productName}>{product?.name || ""}</h2>
            <h3 className={styles.price}>
              {product?.price ? "₹" + product.price : ""}
            </h3>
            <p>{product?.description || ""}</p>
            <br />
            <h3 sx={{ pt: 5 }}>Technical Specifications</h3>
            {(product?.specifications || [])
              .sort((a, b) => a.order - b.order)
              .map((specification) => {
                return (
                  <Grid
                    container
                    className={styles.detailRow}
                    key={specification.label}
                  >
                    <Grid item xs={6} lg={6} className={styles.detailHead}>
                      <span>{specification.label}</span>
                    </Grid>
                    <Grid item xs={6} lg={6} className={styles.detailData}>
                      <span>{specification.value}</span>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
        <Grid className={styles.relatedProduct}>
          <h2>Related Products</h2>
        </Grid>
        <ResponsiveSlider relatedProducts={relatedProducts} />
      </Container>
      <Footer />
    </>
  );
}

export const BASE_URL = "https://yesweigh-website.el.r.appspot.com/api/public";
export const ORG_ID = "onV0eAmXvJChYRI724jC";
export const ROUTES = {
  LOGIN: "/login",
  EMPTY: "/",
  HOME: "/home",
  ABOUT_US: "/about-us",
  CONTACT_US: "/contact-us",
  PRODUCTS: "/products",
  PRODUCT_DETAIL: "/products/:productId",
  NEWS: "/news",
  GALLERY: "/gallery",
  DOWNLOADS: "/downloads",
  E_WASTE_MANAGEMENT: "/e-waste-management",
  PRIVACY_POLICY: "/privacy-policy",
  RETURN_POLICY: "/return-policy",
  COOKIE_POLICY: "/cookie-policy",
  TERMS_AND_CONDITIONS: "/terms-conditions",
  PAGE_404: "/page-404",
};

import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Grid, Paper } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "@mui/material/Container";
import styles from "./product.module.css";
import DefaultProductImage from "../assets/images/default-category.png";
import DefaultCategoryImage from "../assets/images/default-category.png";
// import SliderBanner from "../components/BannerComponent";
import { getCategories, getItems } from "../services/BackendServices";
import Loader from "../components/Loading/index";
import delay from "../components/Loading/LoadingDelay";

const CATEGORY_ID_PARAM = "categoryId";

export default function Products() {
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const _getCategories = async () => {
      setIsLoading(true);
      const _categories = await getCategories();
      setCategories(_categories);
      await delay();
      setIsLoading(false);
    };
    _getCategories();
  }, []);

  useEffect(() => {
    if (!selectedCategory) {
      return;
    }
    const _getItems = async (selectedCategory) => {
      setIsLoading(true);
      const _items = await getItems(selectedCategory.id);
      setItems(_items);
      await delay();
      setIsLoading(false);
    };
    _getItems(selectedCategory).then();
  }, [selectedCategory]);

  useEffect(() => {
    if (!categories.length) {
      return;
    }

    if (!searchParams.has(CATEGORY_ID_PARAM)) {
      setSelectedCategory(categories[0]);
      return;
    }
    const categoryId = searchParams.get(CATEGORY_ID_PARAM);
    const category = categories.find((c) => c.id === categoryId);
    setSelectedCategory(category || categories[0]);
  }, [categories, searchParams, setSearchParams]);

  const setSelectedCategoryClicked = async (category) => {
    if (searchParams.has(CATEGORY_ID_PARAM)) {
      searchParams.delete(CATEGORY_ID_PARAM);
      setSearchParams(searchParams);
    }
    setSelectedCategory(category);
  };

  const onProductClickHandler = (product) => {
    navigate(`/products/${product.id}`);
  };

  const _getItemImage = (item) => {
    const image = item.images ? item.images[0] : {};
    return image?.url || DefaultProductImage;
  };

  return (
    <>
      <Header />
      {/* <SliderBanner /> */}
      <Loader isOpen={isLoading} />
      <Grid className={styles.boxShadow}>
        <Container className={styles.productsSection}>
          <Grid className={styles.category}>
            {/* <Grid className={styles.sectionTitle}>
              <h4>Our Products</h4>
              <h2>Total Weighing Solutions</h2>
            </Grid> */}
            <Grid container className={styles.categoryWrapper}>
              {categories.map((category) => {
                return (
                  <Grid
                    item
                    xs={4}
                    lg={2}
                    key={category.id}
                    className={styles.categoryItemWrapper}
                  >
                    <div
                      onClick={() => setSelectedCategoryClicked(category)}
                      className={`${
                        selectedCategory &&
                        selectedCategory.id === category.id &&
                        styles.categoryItemActive
                      } ${styles.categoryItem}`}
                    >
                      <img
                        alt={category.description}
                        className={styles.categoryImg}
                        src={category.image || DefaultCategoryImage}
                        width="100%"
                        height="100%"
                      />
                    </div>
                    <h4>{category.name}</h4>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Container>
      </Grid>

      <Container>
        <Grid container spacing={2} className={styles.itemCardWrapper}>
          {items.map((item) => {
            return (
              <Grid
                item
                xs={6}
                lg={3}
                key={item.id}
                className={styles.productItem}
                onClick={() => {
                  onProductClickHandler(item);
                }}
              >
                <Paper elevation={3} className={styles.productImg}>
                  <img
                    alt={item.description}
                    src={_getItemImage(item) || DefaultProductImage}
                    width="100%"
                  />
                  <h4>{item.name}</h4>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Container>

      <Footer />
    </>
  );
}

import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import styles from "./ewaste.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { FaAngleDoubleRight } from "react-icons/fa";
import SliderBanner from "../components/BannerComponent";

function createData(slNo, state, city, address, pinCode, email, tollFree) {
  return { slNo, state, city, address, pinCode, email, tollFree };
}

const rows = [
  createData(
    1,
    "Uttar Pradesh",
    "Aligarh",
    "K-68A,Gyan Sarovar Aligarh",
    "202001",
    "ewaste1@rightwaysolutions.co.in",
    "1800-102-0584"
  ),
  createData(
    2,
    "Uttar Pradesh",
    "Ghaziabad",
    "A136,G3,Shalimar Garden Ext-2,Sahibabad",
    "201005",
    "ewaste1@rightwaysolutions.co.in",
    "1800-102-0584"
  ),
  createData(
    3,
    "Uttar Pradesh",
    "Luck now	",
    "4/31, Vikalp Khand, Malhaur Road, Gomti Nagar",
    "226010",
    "ewaste1@rightwaysolutions.co.in",
    "1800-102-0584"
  ),
  createData(
    4,
    "Haryana",
    "Faridabad",
    "4/7, Raushan Nagar, Agwanpur Village",
    "121003",
    "ewaste1@rightwaysolutions.co.in",
    "1800-102-0584"
  ),
  createData(
    5,
    "Cuttack",
    "Faridabad",
    "Near HDFC Bank, Stadium Road,Chandni Chowk",
    "753001	",
    "ewaste5@rightwaysolutions.co.in",
    "1800-102-0584"
  ),
  createData(
    6,
    "Karnataka",
    "Mysore",
    "Door No:240, 12Th Main, 6th cross, Saraswathi Puram",
    "570009",
    "ewaste3@rightwaysolutions.co.in",
    "1800-102-0584"
  ),
  createData(
    7,
    "Bihar",
    "Patna",
    "Market Block, Shivpuri, Near ShivMandir, PO-Anisabad",
    "800002	",
    "ewaste2@rightwaysolutions.co.in",
    "1800-102-0584"
  ),
  createData(
    8,
    "Maharashtra",
    "Ahmednagar",
    "Shop No 21, Tarakpur, Ahmednagar",
    "414001	",
    "ewaste4@rightwaysolutions.co.in",
    "1800-102-0584"
  ),
  createData(
    9,
    "Tamil Nadu",
    "Chennai",
    "2/1, Ground Floor, VallalarSreet, Thirumoolarcolony, Anna Nagar West,Chennai,",
    "600040	",
    "ewaste5@rightwaysolutions.co.in",
    "1800-102-0584"
  ),
  createData(
    10,
    "Kerala",
    "Ernakulum",
    "Building No 66/1964D, 2nd Floor, Arangath Road, Ernakulum North",
    "682018	",
    "ewaste5@rightwaysolutions.co.in",
    "1800-102-0584"
  ),
  createData(
    11,
    "Kerala",
    "Malappuram",
    "42/45 E, Panachikkal Tower, Calicut Road, Thurakkal, Misiri, Narukara,P.O. ,Manjeri",
    "676122	",
    "ewaste5@rightwaysolutions.co.in",
    "1800-102-0584"
  ),
  createData(
    12,
    "Kerala",
    "Trivandrum	",
    "JRA 142 Opp.Axis Bank, Near Police Station, Khzakuttam",
    "695582	",
    "ewaste5@rightwaysolutions.co.in",
    "1800-102-0584"
  ),
  createData(
    13,
    "Telangana",
    "Secundrabad	",
    "1st Floor, 106 107, Kamakshi Commercial Complex, S D Road,",
    "500003	",
    "ewaste5@rightwaysolutions.co.in",
    "1800-102-0584"
  ),
  createData(
    14,
    "Gujrat",
    "Ahmadabad",
    "4th Floor, P/1/41, Varun Appartenrt,Vasantbaug,Opp Gulbai Tekra Pump Station",
    "380006",
    "ewaste5@rightwaysolutions.co.in",
    "1800-102-0584"
  ),
  createData(
    15,
    "Gujrat",
    "Rajkot",
    "3rd Floor, Asha Complex, Canal Road,35 Karanpara Corner",
    "360001	",
    "ewaste5@rightwaysolutions.co.in",
    "1800-102-0584"
  ),
  createData(
    16,
    "West Bangal",
    "Kolkata",
    "Room No.206, 2nd floor,DN- 10, Salt Lake, Sector - V",
    "700091	",
    "ewaste5@rightwaysolutions.co.in",
    "1800-102-0584"
  ),
];
export default function EwasteManagement() {
  return (
    <>
      <Header />
      <SliderBanner />
      <Container>
        <Grid className={styles.eWasteSection}>
          <Grid sx={{ textAlign: "center" }}>
            <h2>Toll Free No:1800-102-0584</h2>
            <h1>YES WEIGH : E-WASTE POLICY</h1>
          </Grid>
          <Grid className={styles.introduction}>
            <h2>Introduction</h2>
            <p>
              Electronic waste, or e-waste, is a term for electronic products
              that have become unwanted, non-working or obsolete, and have
              essentially reached the end of their useful life. As per E-waste
              Rule 2016, the E waste defined as ‘electrical and electronic
              equipment, whole or in part discarded as waste by the consumer or
              bulk consumer as well as rejects from manufacturing, refurbishment
              and repair processes. E-waste contains many valuable, recoverable
              materials such as aluminum, copper, gold, silver, plastics, and
              ferrous metals. In order to conserve natural resources and the
              energy needed to produce new electronic equipment from virgin
              resources, electronic equipment can be refurbished, reused, and
              recycled instead of being land filled. E-waste also contains toxic
              and hazardous materials including mercury, lead, cadmium,
              beryllium, chromium, and chemical flame retardants, which have the
              potential to leach into our soil and water.
            </p>
          </Grid>
          <Grid className={styles.ewasteContents}>
            <h3 className={styles.subHeading}>
              Benefits Of Recycling Of Old Electronic Products/Why Recycle?
            </h3>
            <p>
              <FaAngleDoubleRight className={styles.icon} />
              E-waste contains many valuable, recoverable materials such as
              aluminum, copper, gold, silver, plastics, and ferrous metals. In
              order to conserve natural resources and the energy needed to
              produce new electronic equipment from virgin resources, electronic
              equipment can be refurbished, reused, and recycled instead of
              being land filled.
            </p>
            <p>
              <FaAngleDoubleRight className={styles.icon} />
              E-waste also contains toxic and hazardous materials including
              mercury, lead, cadmium, beryllium, chromium, and chemical flame
              retardants, which have the potential to leach into our soil and
              water.
            </p>
            <p>
              <FaAngleDoubleRight className={styles.icon} />
              Protects your surroundings- Safe recycling of outdated electronics
              promotes sound management of toxic chemicals such as lead and
              mercury.
            </p>
            <p>
              <FaAngleDoubleRight className={styles.icon} />
              Conserves natural resources- Recycling recovers valuable materials
              from old electronics that can be used to make new products. As a
              result, we save energy, reduce pollution, reduce greenhouse gas
              emissions, and save resources by extracting fewer raw materials
              from the earth.
            </p>
            <p>
              <FaAngleDoubleRight className={styles.icon} />
              Helps others - Donating your used electronics benefits your
              community by passing on ready-to-use or refurbished equipment to
              those who need it.
            </p>
          </Grid>
          <Grid>
            <h3 className={styles.subHeading}>
              Recycling And Canalization Of E-Waste:
            </h3>
            <p>
              The company has tied -up with PRO M/s Rightway Solutions For
              collection of all our E-waste on PAN India and disposes the same
              at their recycling partner M/s TRITECH SYSTEMS. Dismantling unit
              at S.F.No.165, Porur Village, Ambattur, Taluk, Thiruvallur
              District. Chennai, India. Customer can reach or call on our PRO’s
              Toll Free No 1800-102-0584. Representatives of PRO explain them
              about process of disposal and make them aware about nearest drop
              point available to drop the e-waste also give information about
              incentive we will offer to them against their end of life product.
              If any customer wants to handover the material from their door
              steps, we do send either logistics team of our PRO to collect the
              items and channelize the same to our e-waste partner plant for
              final processing.
            </p>
          </Grid>
          <Grid className={styles.ewasteContents}>
            <h3 className={styles.subHeading}>Dos &amp; Dont's</h3>
            <h3>Dos:</h3>
            <p>
              <FaAngleDoubleRight className={styles.icon} />
              Always look for information on the catalogue with your product for
              end-of-life equipment handling.
            </p>
            <p>
              <FaAngleDoubleRight className={styles.icon} />
              Ensure that only Authorized Recyclers repair and handle your
              electronic products.
            </p>
            <p>
              <FaAngleDoubleRight className={styles.icon} />
              Always call our E-waste Authorized Collection Centres/points to
              dispose products that have reached end-of life.
            </p>
            <p>
              <FaAngleDoubleRight className={styles.icon} />
              Always drop your used electronic products, batteries or any
              accessories when they reach the end of their life at your nearest
              Authorized E-Waste Collection Centres/Points.
            </p>
            <p>
              <FaAngleDoubleRight className={styles.icon} />
              Wherever possible or as instructed, separate the packaging
              material according to responsible waste disposal options and
              sorting for recycling.
            </p>
            <p>
              <FaAngleDoubleRight className={styles.icon} />
              Always disconnect the battery from product, and ensure any glass
              surface is protected against breakage.
            </p>
            <h3>Donts:</h3>
            <p>
              <FaAngleDoubleRight className={styles.icon} />
              Do not dismantle your electronic Products on your own.
            </p>
            <p>
              <FaAngleDoubleRight className={styles.icon} />
              Do not throw electronics in bins having “Do not Dispose” sign.
            </p>
            <p>
              <FaAngleDoubleRight className={styles.icon} />
              Do not give e-waste to informal (Kabbadi) and unorganized sectors
              like Local Scrap Dealer/ Rag Pickers.
            </p>
            <p>
              <FaAngleDoubleRight className={styles.icon} />
              Do not dispose your product in garbage bins along with municipal
              waste that ultimately reaches landfills.
            </p>
          </Grid>
          <Grid>
            <h3 className={styles.subHeading}>Collection Centres:</h3>
            <p>
              M/s Rightway Sollutions have 16 collection center in PAN India and
              they have agreement with Gati Logistic for collection and
              transportation from customer doorstep/collection center to
              Dismantlers Dismantling unit.
            </p>
            <h3>Collection Center List of RIGHTWAY SOLUTIONS</h3>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead className={styles.tableHeader}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      S.No{" "}
                    </TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Pin Code </TableCell>
                    <TableCell>E Mail Id</TableCell>
                    <TableCell>Toll Free No</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.slNo}>
                      <TableCell>{row.slNo}</TableCell>
                      <TableCell>{row.state}</TableCell>
                      <TableCell>{row.city}</TableCell>
                      <TableCell>{row.address}</TableCell>
                      <TableCell>{row.pinCode}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.tollFree}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

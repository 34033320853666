import axios from "axios";
import { BASE_URL, ORG_ID } from "../const";

export async function getCategories() {
  try {
    const resp = await axios({
      url: `${BASE_URL}/categories`,
      params: { orgId: ORG_ID },
    });
    return resp.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getItems(categoryId) {
  try {
    const resp = await axios({
      url: `${BASE_URL}/products`,
      params: { orgId: ORG_ID, categoryId },
    });
    return resp.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getProductDetails(id) {
  try {
    const resp = await axios({
      url: `${BASE_URL}/products/${id}`,
      params: { orgId: ORG_ID },
    });
    return resp.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

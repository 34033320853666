import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ROUTES } from "./const";
import HomePage from "./pages/HomePage";
import AboutUsPage from "./pages/AboutUsPage";
import ContactUsPage from "./pages/ContactUsPage";
import ProductsPage from "./pages/ProductsPage";
import ProductDetailPage from "./pages/ProductDetailPage";
import GalleryPage from "./pages/GalleryPage";
import EWasteManagementPage from "./pages/EWasteManagementPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ReturnPolicyPage from "./pages/ReturnPolicyPage";
import CookiePolicyPage from "./pages/CookiePolicyPage";
import NotFoundPage from "./pages/NotFoundPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path={ROUTES.EMPTY}
          element={<Navigate to={ROUTES.HOME} />}
        />
        <Route exact path={ROUTES.HOME} element={<HomePage />} />
        <Route exact path={ROUTES.ABOUT_US} element={<AboutUsPage />} />
        <Route exact path={ROUTES.CONTACT_US} element={<ContactUsPage />} />
        <Route exact path={ROUTES.PRODUCTS} element={<ProductsPage />} />
        <Route
          exact
          path={ROUTES.PRODUCT_DETAIL}
          element={<ProductDetailPage />}
        />
        <Route exact path={ROUTES.GALLERY} element={<GalleryPage />} />
        <Route
          exact
          path={ROUTES.E_WASTE_MANAGEMENT}
          element={<EWasteManagementPage />}
        />
        <Route
          exact
          path={ROUTES.PRIVACY_POLICY}
          element={<PrivacyPolicyPage />}
        />
        <Route
          exact
          path={ROUTES.RETURN_POLICY}
          element={<ReturnPolicyPage />}
        />
        <Route
          exact
          path={ROUTES.COOKIE_POLICY}
          element={<CookiePolicyPage />}
        />
        <Route exact path={ROUTES.PAGE_404} element={<NotFoundPage />} />

        <Route path="/*" element={<Navigate to={ROUTES.PAGE_404} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

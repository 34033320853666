import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import styles from "./policy.module.css";
import SliderBanner from "../components/BannerComponent";
import { FaAngleDoubleRight } from "react-icons/fa";
export default function CookiePolicy() {
  return (
    <>
      <Header />

      <SliderBanner />
      <Container>
        <Grid className={styles.privacySection}>
          <Grid sx={{ textAlign: "center" }}>
            <h1>YES WEIGH : COOKIE POLICY</h1>
          </Grid>
          <Grid className={styles.introduction}>
            <p>Last updated: 01 January 2023</p>
            <p>
              we uses cookies on www.yesweigh.in. By using the Service, you
              consent to the use of cookies. Our Cookies Policy explains what
              cookies are, how we use cookies, how third-parties we may partner
              with may use cookies on the Service, your choices regarding
              cookies and further information about cookies.
            </p>
            <p>What are cookies</p>
            <p>
              Cookies are small pieces of text sent by your web browser by a
              website you visit. A cookie file is stored in your web browser and
              allows the Service or a third-party to recognize you and make your
              next visit easier and the Service more useful to you. <br />
              Cookies can be "persistent" or "session" cookies. Persistent
              cookies remain on your personal computer or mobile device when you
              go offline, while session cookies are deleted as soon as you close
              your web browser.
            </p>
            <p>
              How we use cookies When you use and access the Service, we may
              place a number of cookies files in your web browser.
              <br /> We use cookies for the following purposes:
            </p>
            <ul>
              <li>
                <FaAngleDoubleRight className={styles.icon} />
                To enable certain functions of the Service
              </li>
              <li>
                <FaAngleDoubleRight className={styles.icon} />
                To provide analytics
              </li>
              <li>
                <FaAngleDoubleRight className={styles.icon} />
                To store your preferences
              </li>
              <li>
                <FaAngleDoubleRight className={styles.icon} />
                To enable advertisements delivery, including behavioral
                advertising
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Container } from "@mui/system";
import { Grid } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from "./about.module.css";
import SliderBanner from "../components/BannerComponent";
import AboutUsImg from "../assets/images/about.png";
import Loader from "../components/Loading/index";
import delay from "../components/Loading/LoadingDelay";

export default function AboutUs() {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    delay().then(() => {
      setIsLoading(false);
    });
  }, [setIsLoading]);
  return (
    <>
      <Header />
      <SliderBanner />
      <Loader isOpen={isLoading} />
      <Container>
        <Grid container className={styles.aboutUs}>
          <Grid item xs={12} lg={6}>
            <img alt="Interweighing Pvt LTD is the market leader specialized in electronic weighing and billing equipment like Cloud based POS scales" src={AboutUsImg} width="70%" />
          </Grid>
          <Grid item xs={12} lg={6}>
            <p>
              Interweighing Pvt LTD is the market leader specialized in
              electronic weighing and billing equipment like Cloud based POS
              scales, Barcode scales, Industrial scales and Analytical scales.
              We have a prominent role in changing the concept of weighing
              industry. Our main vision is to upgrade the traditional weighing
              concept and familiarize the weighing machines with technology
              backed and accurate measurements, thereby transforming our nation
              to a Digital India.
            </p>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

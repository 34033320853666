import Header from "../components/Header";
import Footer from "../components/Footer";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import styles from "./policy.module.css";
import SliderBanner from "../components/BannerComponent";
export default function PrivacyPolicy() {
  return (
    <>
      <Header />
      <SliderBanner />
      <Container>
        <Grid className={styles.privacySection}>
          <Grid sx={{ textAlign: "center" }}>
            <h1>YES WEIGH : PRIVACY POLICY</h1>
          </Grid>
          <Grid className={styles.introduction}>
            <h4>Privacy Policy</h4>
            <p>
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You
              respond to our advertisements and tells You about Your privacy
              rights and how the law protects You. We use Your Personal Data to
              contact and support you, as well as to provide and improve the
              Service. By using the Service, You agree to the collection and use
              of information in accordance with this Privacy Policy.
            </p>
            <h4>Interpretation and Definitions</h4>
            <h5>Interpretation</h5>
            <p>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
            <h5>Definitions</h5>
            <p>
              For the purposes of this Privacy Policy: <br />
              <br />
              Company (referred to as either "the Company", "We", "Us" or "Our"
              in this Agreement) refers to Interweighing Private Ltd. Device
              means any device that can access the Service such as a computer, a
              cellphone or a digital tablet. Personal Data is any information
              that relates to an identified or identifiable individual. Service
              refers to the advertisement, website, or application. Service
              Provider means any natural or legal person who processes the data
              on behalf of the Company. It refers to third-party companies or
              individuals employed by the Company to facilitate the Service, to
              provide the Service on behalf of the Company, to perform services
              related to the Service or to assist the Company in analyzing how
              the Service is used.
            </p>
            <p>
              Usage Data refers to data collected automatically, either
              generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit).
              You means the individual accessing or using the Service, or the
              company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicable. Collecting and
              Using Your Personal Data Types of Data Collected Personal Data
            </p>
            <p>
              If you have any questions about this Privacy Policy, You can
              contact us via email at sales@yesweigh.in
            </p>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
